import './App.css';
import Header from "./components/dashboard/header/header";
import LoadingScreen from "./components/loading-screen/loading-screen";
import {
    BrowserRouter as Router,
} from 'react-router-dom'
import Main from "./components/dashboard/main/main";

const App = () => {
    return (
        <Router>
            <LoadingScreen/>
            <Header/>
            <Main/>
        </Router>
    );
}
export default App;
