import {
    SAVE_USER_LOGIN,
    SAVE_USER_PROFILE
} from "../actions";

const initialState = {
    isLoggedIn: false,
    token: '',
    userProfile: null
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_USER_LOGIN: {
            return {
                ...state,
                isLoggedIn: action.payload.isLoggedIn,
                token: action.payload.token
            };
        }
        case SAVE_USER_PROFILE: {
            return {
                ...state,
                userProfile: action.payload
            };
        }
        default:
            return state;
    }
};

export default userReducer;
