const getFormattedTime = (params) => {
    return `${params.row.time} H`;
}

const getFullName = (params) => {
    return `${params.row.firstName} ${params.row.lastName}`;
}

const getUserRank = (params) => {
    const style = {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingLeft: '10px',
    };

    return (
        <span style={style}>
            <a href={`https://www.strava.com/athletes/${params.row.id}`} target={'_blank'}
               rel="noreferrer" style={{textDecoration:'none'}}>{params.api.getRowIndex(params.row.id) + 1}</a>
        </span>
    );
}

const getLevel = (params) => {
    let level=''
    const background = () => {
        if(params.row.time < 50) {
            level = '--';

            return '';
        }
        if(params.row.time >= 50 && params.row.time < 120) {
            level = '50 H';

            return '#0362FC';
        }
        if(params.row.time / 3600 >= 300) {
            level = '120 H';

            return '#00ad11';
        }
    }

    const color = () => {
        if(level === '--'){
            return '#000000';
        }

        return '#FFFFFF';
    }

    const style = {
        display: 'flex',
        justifyContent: 'center',
        width: 50,
        padding: '4px 6px',
        alignItems: 'flex-start',
        gap: 4,
        borderRadius: 16,
        background: background(),
        color: color(),
        fontSize: 12,
        letterSpacing: 0.16
    };

    return <span style={style}>{level}</span>;
}

export const dataGridColumns = [
    {field: 'id', headerName: 'Rank', width: 150, renderCell: getUserRank},
    {field: 'fullName', headerName: 'Name', flex: 1, valueGetter: getFullName},
    {field: 'time', headerName: 'Time', flex: 1, valueGetter: getFormattedTime},
    {field: 'badge', headerName: 'Badge', flex: 1, renderCell: getLevel},
];
