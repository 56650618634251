export const SAVE_USER_LOGIN = 'SAVE_USER_LOGIN';
export const saveUserLogin = (payload) => {
    return {
        type: SAVE_USER_LOGIN,
        payload
    };
};

export const SAVE_USER_PROFILE = 'SAVE_USER_PROFILE';
export const saveUserProfile = (payload) => {
    return {
        type: SAVE_USER_PROFILE,
        payload
    }
}

export const SET_LOADING_SCREEN = 'SET_LOADING_SCREEN';
export const setLoadingScreen = (payload) => {
    return {
        type: SET_LOADING_SCREEN,
        payload
    }
}

export const SET_STRAVA_STATS = 'SET_STRAVA_STATS';
export const setStravaStats = (payload) => {
    return {
        type: SET_STRAVA_STATS,
        payload
    }
}
