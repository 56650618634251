import axios from "axios";

const stravaClient = axios.create({baseURL: 'https://spjj3s2a7lx4hhyncjvurhbbgq0rwlsc.lambda-url.eu-central-1.on.aws'});
const googleClient = axios.create({baseURL: 'https://www.googleapis.com/oauth2/v1/userinfo?'});

export const getStats = () => {
    return stravaClient.get('/athletes-stats').then(response => response.data);
}

export const getUserProfile = (token) => {
    return googleClient.get(`access_token=${token}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json'
        }
    }).then(response => response.data);
}
