import {ThreeCircles} from "react-loader-spinner";
import './loading-screen.css';
import {useSelector} from "react-redux";

const LoadingScreen = () => {
    const store = useSelector((state) => state.applicationReducer);

    return (
        store.showLoadingScreen &&
        <div className="spinner-container">
            <ThreeCircles
                height="100"
                width="100"
                color="#0362fc"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
            />
        </div>
    );
}

export default LoadingScreen;
