import {configureStore} from "@reduxjs/toolkit";
import userReducer from "./reducers/userReducer";
import applicationReducer from "./reducers/applicationReducer";
import stravaReducer from "./reducers/stravaReducer";

export default configureStore({
    reducer: {
        userReducer,
        applicationReducer,
        stravaReducer
    }
});
