import Login from "../login/login";
import './main.css';
import ShowAthletes from "./athletes/athletes";
import {useSelector} from "react-redux";

const Main = () => {
    const store = useSelector((state) => state);

    return (
        <div className={'main-container'}>
            { !store.userReducer.isLoggedIn && <Login/> }
            { store.userReducer.isLoggedIn && <ShowAthletes/> }
        </div>
    );
}
export default Main;
