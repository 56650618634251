import { SET_LOADING_SCREEN } from "../actions";

const initialState = {
    showLoadingScreen: false
}

const applicationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING_SCREEN: {
            return {
                ...state,
                showLoadingScreen: action.payload
            };
        }
        default:
            return state;
    }
}
export default applicationReducer;
