import './header.css';
import {batch, useDispatch, useSelector} from "react-redux";
import {googleLogout} from "@react-oauth/google";
import {saveUserLogin, saveUserProfile} from "../../../redux/actions";
import {useState} from "react";
import {Link} from "react-router-dom";

const Header = () => {
    const store = useSelector((state) => state.userReducer);
    const dispatch = useDispatch();

    const [showDropdownMenu, setShowDropdownMenu] = useState(false);

    const logOut = () => {
        localStorage.setItem('access_token', '');
        googleLogout();
        onClickHandler();

        batch(() => {
            dispatch(saveUserLogin({token: '', isLoggedIn: false}));
            dispatch(saveUserProfile(null));
        });
    };

    const onClickHandler = () => {
        setShowDropdownMenu(!showDropdownMenu);
    }

    return (
        store.isLoggedIn && store.userProfile && (
            <div className={'header-container'}>
                <Link to={'/'}>
                    <img src="/fliplogo.png"></img>
                </Link>
                <div className={'profile-container'}>
                    <img src={store.userProfile.picture} referrerPolicy={'no-referrer'}
                         alt="user image" onClick={onClickHandler} className={'profile-image'}/>
                    {
                        showDropdownMenu && (
                            <div className={'profile-dropdown-menu'}>
                                <span onClick={logOut}>Logout</span>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    );
}

export default Header;
