import { SET_STRAVA_STATS } from "../actions";

const initialState = {
    athletes: [],
    overall: [],
    stats: []
};

const stravaReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_STRAVA_STATS: {
            return {
                ...state,
                stats: action.payload
            };
        }
        default:
            return state;
    }
}
export default stravaReducer;
