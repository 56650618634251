import {useEffect, useState} from "react";
import {batch, useDispatch, useSelector} from "react-redux";
import {useGoogleLogin} from "@react-oauth/google";
import {
    saveUserLogin,
    saveUserProfile,
    setLoadingScreen,
    setStravaStats
} from "../../../redux/actions";
import GoogleButton from "react-google-button";
import './login.css';
import {getStats, getUserProfile} from "../../../services/services";

const Login = () => {
    const dispatch = useDispatch();
    const store = useSelector((state) => state.userReducer);

    const [token, setToken] = useState(localStorage.getItem('access_token'));

    const login = useGoogleLogin({
        onSuccess: (response) => {
            localStorage.setItem('access_token', response.access_token);
            const x = response.access_token;
            setToken(x);

            dispatch(saveUserLogin({
                token: response.access_token,
                isLoggedIn: true
            }));
        },
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(() => {
        if (token) {
            dispatch(setLoadingScreen(true));

            getUserProfile(token)
                .then(data => {
                    batch(() => {
                        dispatch(saveUserLogin({token, isLoggedIn: true}));
                        dispatch(saveUserProfile(data));
                        dispatch(setLoadingScreen(false));
                    });
                })
                .catch(() => {
                    localStorage.setItem('access_token', '');
                    setToken('');
                    dispatch(setLoadingScreen(false));
                });

            getStats().then(data => {
                batch(() => {
                    dispatch(setStravaStats(data));
                    dispatch(setLoadingScreen(false));
                });
            });
        }
    }, [token]);

    if (store.isLoggedIn)
        return null;

    return (
        <div className={'login-container'}>
            <div className={'login-details'}>
                <div className={'login-left-side'}>
                    <img src="/flipmagazine.jpeg" alt="flipsnackMangazine"/>
                </div>
                <div className={'login-right-side'}>
                    <div className={'login-right-side-container'}>
                        <h1>Strava Dashboard</h1>
                        <h4>Powered by</h4><img src="/flipsnacklogo.svg" alt={'random-icon'}/>
                        <br/>
                        <br/>
                        <GoogleButton onClick={() => login()} className={'login-button'}></GoogleButton>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Login;
