import { useSelector } from "react-redux";
import {
    EmojiEventsOutlined,
    EmojiFlagsOutlined,
    WorkspacePremiumOutlined
} from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { dataGridColumns } from "./helpers/statsTableUtils";
import './athletes.css';

const ShowAthletes = () => {
    const store = useSelector((state) => state.stravaReducer);

    let totalMovingTime = store.stats.reduce((acc, curr) => acc + curr.totalMovingTime, 0);
    totalMovingTime = (totalMovingTime / 3600).toFixed(2)
    const targetPercent = ((totalMovingTime * 100) / 5000).toFixed(2);

    let dataRows = store.stats
        .sort((a, b) => a.totalMovingTime < b.totalMovingTime ? 1 : -1)
        .map(item => {
            return {
                id: item.athleteId,
                firstName: item.firstname,
                lastName: item.lastname,
                time: (item.totalMovingTime / 3600).toFixed(2),
                badge: ''
            }
    });

    const backgroundColor = () =>{
        if(targetPercent < 50) return '#FFC600'

        if(targetPercent >= 50 && targetPercent < 100) return '#0362FC'

        return '#00ad11'
    }

    return (
        <>
            <div className="summary-container">
                <span className="total-hours">{totalMovingTime} H</span>
                <span className="quote">That's how far we got together!</span>
                <div className="progress-container">
                    <div className="steps-container">
                        <div className="progress-step">
                            <EmojiFlagsOutlined></EmojiFlagsOutlined>
                            <span className="progress-step-label">Start</span>
                        </div>
                        <div className="progress-step">
                            <WorkspacePremiumOutlined></WorkspacePremiumOutlined>
                            <span className="progress-step-label">2500 H</span>
                        </div>
                        <div className="progress-step">
                            <EmojiEventsOutlined></EmojiEventsOutlined>
                            <span className="progress-step-label">5000 H</span>
                        </div>
                    </div>
                    <div className="progress-bar-container">
                        <div className="target"/>
                        <div
                            className="achieved"
                            style={{
                                width: totalMovingTime > 3000 ? '100%' : targetPercent + '%',
                                backgroundColor: backgroundColor()
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="stats-container">
                <DataGrid
                    getRowId={(row) => row.id}
                    rows={dataRows}
                    columns={dataGridColumns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    autoHeight={true}
                    sx={{
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 700
                        }, width: '100%'
                    }}
                />
            </div>
        </>
    );
}
export default ShowAthletes;
